import { useState, useEffect } from 'react';

const useAffix = (contentNavSelector: string, contentMainSelector: string, contentMainShift: number = 0): [string, React.CSSProperties, number] => {
  const [affix, setAffix] = useState<string>('affix-top');
  const [style, setStyle] = useState<React.CSSProperties>({});

  useEffect(() => {
    const handleScroll = (): void => {
      const contentNav = document.querySelector(contentNavSelector) as HTMLElement;
      const contentMain = document.querySelector(contentMainSelector) as HTMLElement;

      if (!contentNav || !contentMain) return;

      const scrollPosition: number = window.scrollY;
      const contentNavHeight: number = contentNav.offsetHeight;
      const contentMainBottom: number = contentMain.getBoundingClientRect().bottom + scrollPosition + contentMainShift;

      if (scrollPosition + contentNavHeight >= contentMainBottom) {
        setAffix('affix-bottom');
        setStyle({
          position: 'absolute',
          top: `${contentMainBottom - contentNavHeight}px`
        });
      } else if (scrollPosition > contentNav.offsetTop) {
        setAffix('affix');
        setStyle({
          position: 'fixed',
          top: '60px'
        });
      } else {
        setAffix('affix-top');
        setStyle({});
      }
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll();

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return [affix, style, contentMainShift];
};

export default useAffix;
