import React, { useEffect, useState } from 'react';
import { Table, Spin, Alert } from 'antd';
import { TablePaginationConfig } from 'antd/lib/table';
import { FindOrdersType, TransactionResultTableProps } from '../types/types';
import { fetchData, ApiResponse } from '../services/apiService';

// Define extended pagination config
interface ExtendedPaginationConfig extends TablePaginationConfig {
  total: number;
}

// Map API data to FindOrdersType interface
const mapDataToFindOrdersType = (data: any[]): FindOrdersType[] => {
  if (!data || data.length === 0) {
    return [];
  }
  return data.map((item) => {
    const createdAt = new Date(item.created_at);
    const date = createdAt.toLocaleDateString();
    const time = createdAt.toLocaleTimeString(undefined, { hour12: false });

    return {
      key: item.order_id,
      orderId: item.order_id,
      date: date,
      time: `${time} (UTC)`,
      amount: `${item.amount} ${item.currency}`,
      status: item.code_business_status,
      website: item.url_public,
      username: item.user_name,
    };
  });
};

// Generate unique row key for table records
const generateRowKey = (record: FindOrdersType) => {
  const randomString = Math.random().toString(36).substring(2, 10) || record.orderId;
  return randomString
};


/**
 * FindOrdersResultTable
 * 
 * Component to display order history table.
 * Fetches data from API based on search criteria.
 */
const FindOrdersResultTable: React.FC<TransactionResultTableProps> = ({
  lastFourDigits,
  emailOrUsername,
  formSubmitted
}) => {
  const [data, setData] = useState<FindOrdersType[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [pagination, setPagination] = useState<ExtendedPaginationConfig>({
    current: 1,
    pageSize: 20,
    total: 0,
  });

  // Fetch data from API when form submitted or pagination changes
  useEffect(() => {
    const load = async () => {
      setLoading(true);

      // Construct criteria array for API request
      const criterias = [];
      const defaultCriteria = { column: 'email', value: 'unknowm@mail' };
      if (emailOrUsername) {
        criterias.push({ column: emailOrUsername.column, value: emailOrUsername.value });
      } else {
        criterias.push(defaultCriteria);
      }
      if (lastFourDigits) {
        criterias.push({ column: lastFourDigits.column, value: lastFourDigits.value });
      } else {
        criterias.push(defaultCriteria);
      }

      // Construct request body for API request
      const requestBody = {
        filter: criterias,
        pageNumber: pagination.current,
        pageSize: pagination.pageSize,
      };

      // Fetch data from API
      const responseData: ApiResponse | null = await fetchData('orders', 'POST', requestBody);

      // Handle API response
      if (data && responseData.success) {
        const transformedData = mapDataToFindOrdersType(responseData.data);
        setData(transformedData);
        if (responseData.total !== pagination.total) {  
          setPagination(prev => ({ ...prev, total: responseData.total ?? prev.total }));
        }
      } else if (responseData && !responseData.success) {
        setError('Service is not available temporary.');
      } else {
        // Handle null response (e.g., network error)
        setError('Service is not available temporary.');
      }
      setLoading(false);
    }

    if (formSubmitted && lastFourDigits && emailOrUsername) {
      load();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastFourDigits, emailOrUsername, formSubmitted, pagination.current, pagination.pageSize]);

  // Handle table pagination change
  const handleTableChange = (
    newPagination: TablePaginationConfig
  ) => {
    const current = newPagination.current || 1;
    const pageSize = newPagination.pageSize || 10;
    
    setPagination(prev => ({
      ...prev,
      current,
      pageSize,
    }));
  };

  // Define table columns
  const columns = [
    {
      title: 'ORDER ID',
      dataIndex: 'orderId',
      key: 'orderId',
      className: 'column-orderId',
    },
    {
      title: 'DATE',
      dataIndex: 'date',
      key: 'date',
      className: 'column-date',
    },
    {
      title: 'TIME',
      dataIndex: 'time',
      key: 'time',
      className: 'column-credit-card',
    },
    {
      title: 'AMOUNT',
      dataIndex: 'amount',
      key: 'amount',
      className: 'column-offer',
    },
    {
      title: 'STATUS',
      dataIndex: 'status',
      key: 'status',
      className: 'column-status',
      render: (status: string) => (
        <span className={`status-${status.toLowerCase()}`}>{status}</span>
      ),
    },
    {
      title: 'USERNAME',
      dataIndex: 'username',
      key: 'username',
      className: 'column-username',
    },
    {
      title: 'WEB SITE',
      dataIndex: 'website',
      key: 'website',
      className: 'column-website',
    }
  ];

  return (
    <div className="well" style={{ background: loading ? 'transparent' : '#fff' }}>
      <Spin spinning={loading} size="large" tip="Loading...">
        {error ? (
          <Alert message={error} type="error" showIcon closable onClose={() => setError(null)} />
        ) : (
          <>
            {!loading && (
              <>
                <h2 className="well-title text-center">Order History</h2>
                <Table
                  columns={columns}
                  dataSource={data}
                  pagination={{
                    ...pagination,
                    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} orders`,
                  }}
                  onChange={handleTableChange}
                  rowKey={generateRowKey}
                />
              </>
            )}
          </>
        )}
      </Spin>
    </div>
  );
};

export default FindOrdersResultTable;
