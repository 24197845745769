import React from "react";

interface InfoProps {
  title?: string;
  subtitle?: string;
  description?: React.ReactNode;
}

const BlockInfo: React.FC<InfoProps> = ({ title, subtitle, description }) => {
    return (
      <div className="block-info">
        {title && <h1>{title}</h1>}
        {description && <p>{description}</p>}
        {subtitle && <h2>{subtitle}</h2>}
      </div>
    );
  };

export default BlockInfo;