import React, { useState, useEffect } from 'react';
import Main from "../components/Main";
import BlockInfo from "../components/BlockInfo";
import FindOrdersForm from "../components/FindOrdersForm";
import { useTranslation } from 'react-i18next';

const FindOrders = () => {
  const { t } = useTranslation();

  const [pageTitle, setPageTitle] = useState(t('cards.data.findOrders.pageTitle'));
  const [pageDescription, setPageDescription] = useState(t('cards.data.findOrders.pageDescription'));

  useEffect(() => {
    setPageTitle(t('cards.data.findOrders.pageTitle'));
    setPageDescription(t('cards.data.findOrders.pageDescription'));
  }, [t]);

  const handleFormSubmission = () => {
    setPageTitle(t('cards.data.findOrders.submittedPageTitle'));
    setPageDescription(t('cards.data.findOrders.submittedPageDescription'));
  };

  return (
    <Main>
      <BlockInfo
        title={pageTitle}
        description={pageDescription}
      />
      <FindOrdersForm onFormSubmit={handleFormSubmission}/>
    </Main>
  );
};

export default FindOrders;