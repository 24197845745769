import React from 'react';

// Hook
import ScrollClassComponent from './hooks/useScrollClass';

// Providers
import I18nProvider from './i18n/i18n';
import { LanguageProvider } from './components/LanguageContext';

// Router
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

// Pages
import HomePage from "./pages/Home";
import FaqPage from "./pages/FaqPage";
import MainLayout from "./pages/MainLayout";
import PrivacyPolicyPage from "./pages/PrivacyPolicy";
import CustomerSupportPage from "./pages/customerSupport";
import FindOrdersPage from "./pages/findOrders";
import CancelTransactionPage from "./pages/cancelSubscriptions";
import TermsAndConditionsPage from "./pages/TermsAndConditions";

// Components
import SwaggerUIFrame from "./components/SwaggerUIFrame";

// App
const App: React.FC = () => {

  const router = createBrowserRouter([
    {
      path: "/",
      element: (
        <MainLayout />
      ),
      children: [
        {
          path: "",
          element: <HomePage />
        },
        {
          path: "/secure/findOrders",
          element: <FindOrdersPage />
        },
        {
          path: "/secure/cancelSubscriptions",
          element: <CancelTransactionPage />
        },
        {
          path: "/secure/customerSupport",
          element: <CustomerSupportPage />
        },
        {
          path: "/secure/termsAndConditions",
          element: <TermsAndConditionsPage />
        },
        {
          path: "/secure/privacyPolicy",
          element: <PrivacyPolicyPage />
        },
        {
          path: "/secure/faq",
          element: <FaqPage />
        },
        // Non-existent routes redirect to the homepage
        {
          path: "/*",
          element: <HomePage />
        },
      ]
    },
    {
      path: "/api-docs",
      element: <SwaggerUIFrame />
    }
  ])


  return (
    <div>
      <ScrollClassComponent />
      <LanguageProvider>
        <I18nProvider host={window.location.host} />
        <RouterProvider router={router} />
      </LanguageProvider>
    </div>
  );
}

export default App;
