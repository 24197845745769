import React from "react";
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

interface Contact {
  country: string;
  phone: string;
}

interface BlockContactsProps {
  contacts: Contact[];
}

const BlockContacts: React.FC<BlockContactsProps> = ({ contacts }) => {
  const { t } = useTranslation();

  return (
    <div className="block-contact">
      <h2>{t('contacts.title')}</h2>
      <div className="block-contact-wrapper">
        <div className="contacts contacts-phones">
          {contacts.map((contact, index) => (
            <div className="contact" key={`contact-${index}`}>
              <div className="contact-content">
                <h3 className="contact-title">{contact.country}</h3>
                <Link to={`tel:${contact.phone}`}>
                  <span className="contact-item contact-phone">
                    <i className="icon icon-phone"></i>
                    {contact.phone}
                  </span>
                </Link>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default BlockContacts;
