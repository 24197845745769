import Main from "../components/Main";
import Cards from "../components/Cards";
import BlockInfo from "../components/BlockInfo";
import BlockAdditionalInfo from "../components/BlockAdditionalInfo";
import { useTranslation } from 'react-i18next';

const Home: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Main>
      <BlockInfo
        title={t('home.title')}
        subtitle={t('home.subtitle')}
        description={t('home.description')}/>
      <Cards />
      <BlockAdditionalInfo
        title={t('home.additionalInfo.title')}
        subtitle={t('home.additionalInfo.subtitle')}
        description={t('home.additionalInfo.description')}
        href={t('home.additionalInfo.href')}
        btnName={t('home.additionalInfo.btnName')}
      />
    </Main>
  );
};

export default Home;