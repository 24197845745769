import React, { useState, useEffect } from 'react';
import Main from "../components/Main";
import useAffix from '../hooks/useAffix';
import SmoothScrollLink from '../hooks/SmoothScrollLink';

const sections = [
    { id: "terms-conditions", name: "Terms and Conditions" },
    { id: "definitions", name: "Definitions" },
    { id: "service-description", name: "1. Service Descriptoin" },
    { id: "payment-for-service", name: "2. Payment for the Service" },
    { id: "registration-and-registration-data", name: "3. Registration and Registration Data" },
    { id: "contracts-for-products-and-services-of-third-parties", name: "4. Contracts for Products and Services of Third Parties" },
    { id: "interruptions-or-discontinuation-of-service", name: "5. Interruptions or Discontinuation of Service" },
    { id: "limitation-of-liabilities-and-disclaimer-of-warrantie", name: "6. Limitation of liabilities and disclaimer of warrantie" },
    { id: "personal-data", name: "7. Personal Data" },
    { id: "intellectual-property-rights", name: "8. Intellectual Property Rights" },
    { id: "indemnification", name: "9. Indemnification" },
    { id: "arbitration-and-governing-law", name: "10. Arbitration and Governing Law" },
    { id: "miscellaneous", name: "11. Miscellaneous" },
    { id: "contact-information", name: "Contact information" },
];

const TermsAndConditionsPage = () => {
    const [affixClass, style] = useAffix('#content-nav', '#content-main', -112);
    const [activeSection, setActiveSection] = useState(sections[0].id);

    const handleScroll = () => {
        const currentSection = sections.find(({ id }) => {
          const el = document.getElementById(id);
          if (el) {
            const scrollPosition = window.scrollY + window.innerHeight / 5;
            return scrollPosition >= el.offsetTop && scrollPosition <= el.offsetTop + el.offsetHeight;
          }
          return false;
        });
    
        if (currentSection && currentSection.id !== activeSection) {
            setActiveSection(currentSection.id);
        }
    };
    
    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [activeSection]);

    return (
        <Main>
        <div>
        <div className="row">
            <div className="col-md-2 col-md-offset-2">
                <div id="content-nav" className={`content-nav ${affixClass}`} style={style} data-affix-target="#content-main">
                <ul className="nav">
                        {sections.map(({ id, name }) => (
                            <li key={id} className={activeSection === id ? "active" : ""}>
                            <SmoothScrollLink to={`#${id}`}>{name}</SmoothScrollLink>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>

            <div className="col-md-6" id="content-main">
                <div className="content-section" id="terms-conditions">
                    <h1>Terms and Conditions</h1>
                    <p><strong>CommerceGate Payment Solutions S.L.</strong>, a corporation organized and existing under the laws of the Spain, C/Moll de Barcelona, “World Trade Center”, North building, 4th floor. Barcelona, Spain. 08039. (hereafter referred to as the “Provider” or “CommerceGate”) processing and billing services, (hereafter referred to as “Service”). The Service may also include access to products and services of third parties.</p>
                    <p>Registration with the Service creates a contract between the end user (hereafter referred to as the “User” or “you”) and the Provider.</p>
                    <p>The User confirms that he has read and understood these conditions of contract and that he accepts them fully and without reservation. These “Terms &amp; Conditions” (hereafter referred to as the “Terms”) have exclusive validity unless the Provider has expressly declared its agreement to different provisions.</p>
                    <p>You may print or save a copy of these Terms for Your reference but the most up to date version of these Terms will always be made available here whilst this Site is operational.</p>
                </div>

                <div className="content-section" id="definitions">
                    <h2>Definitions:</h2>
                    <h3>CommerceGate</h3>
                    <p><b>CommerceGate Payment Solutions S.L.</b> , a corporation organized and existing under the laws of the Spain, C/Moll de Barcelona, “World Trade Center”, North building, 4th floor. Barcelona, Spain. 08039. CommerceGate is a EU authorized Payment Institution, licensed by the Bank of Spain under the license number 6896.</p>
                    <h3>Merchant</h3>
                    <p>A natural person or legal person having legal capacity that (i) sells goods and/or services over the Internet and (ii) has signed the Agreement with CommerceGate.</p>
                    <h3>Payment Gateway</h3>
                    <p>Infrastructure operated by Provider which enables transfer of data to information systems of banks and other financial institutions where online payment transactions initiated by the Customers take place.</p>
                    <h3>Acquiring Service</h3>
                    <p>Services that enable execution of payment transactions initiated by, or via, the Merchant, by means of the User’s payment instrument, which service involves, among else, operating authorization process, transmitting payment orders (Payments) to the Intermediary Institutions, and is designed to provide the Merchant with funds due to it (the Merchant).</p>
                    <h3>Intermediary Institution</h3>
                    <p>Institution which participates in the process of transferring Customer’s financial funds in favor of the Merchant in order to effect a Payment, especially a bank, acquiring and clearing center, payment service provider, Card Associations such as MasterCard, American Express, JCB, Diners Club, electronic money institution etc.</p>
                    <h3>User</h3>
                    <p>A person who pays for goods or services offered by the Merchant on its website.</p>
                    <h3>Agreement</h3>
                    <p>An agreement for the Payment Gateway services signed between the Merchant and Commercegate.</p>
                </div>
                <div className="content-section" id="service-description">
                    <h2>1. Service Description</h2>
                    <p>1.1. YOU REPRESENT AND WARRANT THAT YOU ARE OF LEGAL AGE IN THE JURISDICTION IN WHICH YOU RESIDE.</p>
                    <p>1.2. If any upgrade in or to the Service requires changes in your equipment or software, you must effect these changes at your own expense. Unless explicitly stated otherwise, any new or additional features that change or enhance the current Service, including new products, shall be subject to the terms and conditions of this Agreement.</p>
                </div>
                <div className="content-section" id="payment-for-service">
                    <h2>2. Payment for the Service</h2>
                    <p>2.1. Payment for services under this website may be effectuated by a credit card, debit card etc. (“Approved Facility”). You authorize Provider and/or its authorized agents to transact such payments on your behalf. Unless you have good reason to believe Credit/Debit card you use to purchase your product/service is lost or stolen, you agree not to report that Credit/Debit card as lost or stolen. You agree not to dispute any authorized charge by CommerceGate. In case you fraudulently report your Credit/Debit card used to get services or goods as stolen, or if you fraudulently report that an authorized charge by the CommerceGate is unauthorized, you shall be liable to CommerceGate for liquidated damages of EUR 25,000.00. The liability for liquidated damages specified in this section shall not limit any other liability you may have for breach(-es) of any other terms, conditions, promises and warranties set forth in this terms and conditions</p>
                </div>
                <div className="content-section" id="registration-and-registration-data">
                    <h2>3. Registration and Registration Data</h2>
                    <p>3.1. You agree that you are responsible for keeping your screen name and password for your account secret. You acknowledge that we are not responsible for third party access to your account that results from sharing your login or other account details or personal information by you or the theft or misappropriation of your screen name and password. We reserve the right to suspend your access to the Services or terminate your account if we suspect an unauthorised person is attempting to access it.</p>
                    <p>3.2. If you use a password that the Provider considers to be insecure, the Provider shall be entitled to require the password to be changed and/or terminate your authorized access to password protected areas.</p>
                    <p>3.3. In exchange for your use of the Service (in addition to the payment of applicable fees) you agree to provide true, accurate and complete information about yourself as prompted by the registration form (“Registration Data”). The Provider has any grounds to suspect that the Registration Data is inaccurate or incomplete, the Provider has the right to suspend or terminate your account and refuse any and all current or future use of the Service.</p>
                </div>
                <div className="content-section" id="contracts-for-products-and-services-of-third-parties">
                    <h2>4. Contracts for Products and Services of Third Parties</h2>
                    <p>4.1. The Service may also include access to products and services of independent third parties either directly or via links to sites operated by such third parties.</p>
                    <p>4.2. THE PROVIDER SHALL NOT BE A PARTY TO, OR IN ANY WAY RESPONSIBLE FOR, ANY TRANSACTION CONCERNING PRODUCTS OR SERVICES MADE AVAILABLE FROM SUCH THIRD PARTIES OR FOR ANY CONTENT OR INFORMATION PRESENTED IN CONNECTION WITH ANY PRODUCTS OR SERVICES OF THIRD PARTIES.</p>
                </div>
                <div className="content-section" id="interruptions-or-discontinuation-of-service">
                    <h2>5. Interruptions or Discontinuation of Service</h2>
                    <p>5.1. The Provider may suspend the Service for repair, maintenance, and/or upgrade work. In such event, the Provider shall make reasonable efforts to keep the said interruption as brief as is reasonably possible with as little inconvenience to you as is reasonably possible.</p>
                    <p>5.2. The Provider reserves the right to modify or discontinue, temporarily or permanently, individual services provided by it or third parties via the Services, or the Services themselves.</p>
                    <p>5.3. The Provider aims to make the system as widely accessible as possible. Because of the technical aspects of Internet and mobile phone connections, over which the Service has no influence, disruptions may however arise from time to time in the data transfer. The Provider cannot therefore give any guarantee on the accessibility of the system or for the accessibility of individual participants, however providing best possible performance is one of the main goals of the Provider.</p>
                </div>
                <div className="content-section" id="limitation-of-liabilities-and-disclaimer-of-warrantie">
                    <h2>6. Limitation of liabilities and disclaimer of warrantie</h2>
                    <p>6.1. Except in jurisdictions where such provisions are restricted, you agree that the Provider’s entire liability to you or any third person, and your or any third person’s exclusive remedy, in law, in equity, or otherwise, with respect to the service(s) provided under this Agreement and/or for any breach of this Agreement is solely limited to the amount you paid for such service(s) during the term of this Agreement.</p>
                    <p>6.2. Except in jurisdictions where such provisions are restricted, the Provider, its licensors and contractors (including any third parties providing all or part of the services) shall not be liable for any indirect, incidental, special or consequential damages. Even if the Provider has been advised of the possibility of such damages, to the extent that a country does not permit the exclusion or limitation of liability as set forth herein, the Provider liability is limited to the extent permitted by law in such Country.</p>
                    <p>6.3. You are fully responsible for all activities within your password or account or through your Subscription. That responsibility includes, but is not limited to, the responsibility for any fees for the use of the Service or any products and services made available through the Service.</p>
                    <p>6.4. You agree to notify the Provider immediately if you suspect unauthorized use of your password or account or any other breach of security, and to ensure that you log out of your account at the end of each session. The Provider cannot and will not be liable for any loss or damage arising from your failure to comply with this Section.</p>
                    <p>6.5. The Provider will take reasonable measures to maintain the privacy and security of all private information provided by you to the website, but third parties (such as hackers) may breach or attempt to breach the provider’s security measures or may gain unauthorized access to the Provider or other equipment containing your information. You agree that the Provider shall not be liable for damages of any sort, whether arising under contract, tort, or otherwise, with respect to any breach of security of the Provider website or any other company equipment or user information.</p>
                    <p>6.6. Without limitation to the foregoing, and due to the complexity, variability and number of intermediaries/third parties involved with the provision of our Services, Internet connection, the development and speed of development, along with the spread of malicious code over the Internet, the Provider cannot give any guarantees as to the provision or constant provision of services, services being available by any particular time, or integrity of data stored or transmitted via our system or the Internet. The Provider will use all reasonable efforts to ensure that its systems are secured from any and all unauthorized access or the effects of any malicious code, but will not be held liable for any or all inadvertent disclosure of, corruption or erasure of any or all data transmitted, received or stored on its systems despite their efforts.</p>
                    <p>6.7. CommerceGate may not be held liable as regards the provision to CommerceGate’s servers of transaction data regarding Payments. The Merchant shall be exclusively responsible for the security of data transmission to CommerceGate servers, as well as for correctness and appropriate format of the data. As regards a loss of data after they have been submitted by the Merchant to CommerceGate, CommerceGate disclaims any and all liability for any harm, loss or injury resulting from the fact that no proper backup copy was made by the Merchant (or a third party authorized by the Merchant in this respect) on the Merchant’s systems prior to transmission, if such backup is permitted under the applicable law and requirements of relevant Intermediary Institutions.</p>
                </div>
                <div className="content-section" id="personal-data">
                    <h2>7. Personal Data</h2>
                    <p>7.1. Data protection is of upmost importance to the Provider. The Provider handles any data strictly confidentially and in accordance with the respective data protection regulations, including General Data Protection Regulation (Regulation (EU) 2016/679), “GDPR”. User data is always stored securely. However to operate such a system and to prepare invoices it is sometimes necessary to store data and pass data on to third parties. Therefore the User understands that the Provider will be able to collect, process and store personal, invoicing, mail, chat and access data.</p>
                    <p>7.2. By proceeding to use the Provider services you acknowledge that Provider, including CommerceGate and other group entities, will collect, process and store your personal data and may share such data with other EEA regulated entities, exercising our rights and obligations as Data Controller and Data Processor, depending on a role carried out during the provision of payment services.</p>
                    <p>7.3. Data will be used for processing of payment orders, invoicing, for technical administration, for consumer information according to our privacy policy, for fulfilling of legal and reporting obligations.</p>
                    <p>7.4. You may have the following rights in regards to collection and processing of your personal data – the right to be informed, the right of access, the right to rectification, the right to erasure, the right to restrict processing, the right to data portability, the right to object, the rights in relation to automated decision making and profiling. Provider may not be able to allow enforcement of some of these rights if such rights are superseded by legal obligations of Provider – in this case a detailed explanation will be provided by Provider.</p>
                    <p>7.5. We process information about you in accordance with our Privacy Policy. By using the Services, you consent to such collection and processing and you warrant that all data provided by you is accurate. You are responsible for reviewing the terms of the Privacy Policy, awareness and execution of your rights. By continuing to use the Services you are confirming that you accept the Terms of the Privacy Policy.</p>
                    <p>7.6. You expressly authorize The Provider to monitor, record and log all your online activities on the website (including chat, email), according to business and legal grounds described in Privacy Policy.</p>
                    <p>7.7. You agree that in order to fulfil this Contract your Personal data may be transferred to a third country. Provider will have data protection processes in place to ensure such transfers comply with requirements of Regulation (EU) 2016/679.</p>
                    <p>7.8. You can exercise your rights, or get an answer in regards to any questions or requests for information regarding data protection via an email to <a href="mailto:dpo@cgpaytech.com">dpo@cgpaytech.com</a> or by sending a letter to our legal address. You must identify yourself and provide proof of identification to ensure that the answer is actually provided to the affected person.</p>
                </div>
                <div className="content-section" id="intellectual-property-rights">
                    <h2>8. Intellectual Property Rights</h2>
                    <p>8.1. Except as otherwise set forth herein, all right, title and interest in and to any intellectual property, proprietary rights or other rights related to intangible property which are used, developed, comprising, embodied in, or practiced in connection with any of the Service (“the Provider Intellectual Property Rights”) are owned by the Provider or its licensors, and you agree to make no claim of interest in or ownership of any such the Provider Intellectual Property Rights.</p>
                    <p>8.2. You acknowledge that no title to the Provider Intellectual Property Rights is transferred to you, and that you do not obtain any rights, express or implied, in the Service, other than the rights expressly granted in this Agreement.</p>
                    <p>8.3. If you are a copyright owner or agent thereof and believe that any of our content infringes upon your copyright, please contact us on the following email address: <a className="contact-item contact-email" href="mailto:legal@cgpaytech.com">legal@cgpaytech.com</a></p>
                </div>
                <div className="content-section" id="indemnification">
                    <h2>9. Indemnification</h2>
                    <p>9.1. You agree to indemnify and hold the Provider, its parent, subsidiaries, affiliates, officers, agents, directors, contractors, licensors and other partners and employees, harmless from any loss, liability, claim, or demand, including reasonable legal fees, made by any third party due to or arising out of or in connection with (a) your use of the Service, and (b) the breach by you of your representations and warranties set forth herein.</p>
                </div>
                <div className="content-section" id="arbitration-and-governing-law">
                    <h2>10. Arbitration and Governing Law</h2>
                    <p>10.1. The exclusive means of resolving any dispute or claim arising out of or relating to these Terms of Use (including any alleged breach thereof), the Service, or the Website shall be Arbitration.</p>
                    <p>10.2. All disputes or claims arising out of or in connection with this contract including disputes relating to its validity, breach, termination or nullity shall be finally settled under the Rules of Arbitration of the International Arbitral Centre of the Austrian Federal Economic Chamber in Vienna (Vienna Rules) by one arbitrator appointed in accordance with the said Rules.</p>
                    <p>10.3. The number of arbitrators shall be one.</p>
                    <p>10.4. The substantive law of England and Wales shall be applicable.</p>
                    <p>10.5. The language to be used in the arbitral proceedings shall be English.</p>
                    <p>10.6. By using the Website or the Service in any manner, you agree to the above arbitration agreement. In doing so, YOU GIVE UP YOUR RIGHT TO GO TO COURT to assert or defend any claims between you and the Provider (except for matters that may be taken to small-claims court). YOU ALSO GIVE UP YOUR RIGHT TO PARTICIPATE IN A CLASS ACTION OR OTHER CLASS PROCEEDING. Your rights will be determined by a NEUTRAL ARBITRATOR, NOT A JUDGE OR JURY. You are entitled to a fair hearing before the arbitrator. The arbitrator can grant any relief that a court can, but you should note that arbitration proceedings are usually simpler and more streamlined than trials and other judicial proceedings.</p>
                    <p>10.7. Pre-Arbitration Dispute Resolution. We are always interested in resolving disputes amicably and efficiently. So before you commence an arbitration, we suggest that you contact us to explain your complaint, as we may be able to resolve it without the need for arbitration. You may contact us at <a className="contact-item contact-email" href="mailto:legal@cgpaytech.com">legal@cgpaytech.com</a></p>
                </div>
                <div className="content-section" id="miscellaneous">
                    <h2>11. Miscellaneous</h2>
                    <p>11.1. The Provider shall serve notices related to this contract by posting them on the Provider web site or by sending them to the e-mail address you have given to the Provider, or as a text message to your mobile phone number.</p>
                    <p>11.2. You may not assign or transfer in any other way the contract or any of your contractual rights.</p>
                    <p>11.3. The Provider shall have the right to assign its rights and obligations under this contract and any receivables based on this contract to a third party upon written notice to you, provided such assignment is made under terms not less favorable to you than those in these TERMS.</p>
                    <p>11.4. YOU ACKNOWLEDGE AND AGREE THAT THIS AGREEMENT IS SUBJECT TO CHANGE BY THE PROVIDER AT ANY TIME, WHICH CHANGES SHALL BE EFFECTIVE UPON POSTING SUCH CHANGES TO THE WEBSITE. ANY USE OF THE SERVICE AFTER SUCH POSTING SHALL CONSTITUTE ACCEPTANCE OF SUCH CHANGES BY YOU.</p>
                    <p>11.5. If a Court decides that any provision of this Contract cannot be enforced, that particular part of the Contract will not apply but the rest will. However the parties will negotiate in good faith to restate such provision to reflect the original intentions of the parties as nearly as is possible in accordance with applicable law, and the remaining provisions of this Agreement. The invalidity, illegality or unenforceability of any provisions or part of any provision of this Agreement shall not affect or impact the continuation in force of the remainder of this Agreement, or provisions as the case may be.</p>
                    <p>11.6. The section headings appearing in this Agreement are inserted only as a matter of convenience and in no way define, limit, construe or describe the scope or extent of such section or in any way affect such section.</p>
                    <p>11.7. Where the context so admits or requires, words denoting the singular include the plural and vice versa, words denoting any gender (or the neuter) include both genders and the neuter. Section headings are purely for ease of reference and do not form part of or affect the interpretation of this Agreement. References to “include” and “including” shall be deemed to mean respectively “include(s) without limitation” and “including without limitation”. References to each party to this Agreement include references to its successors and permitted assigns.</p>
                    <p>11.8. These Terms are only available in the English language.</p>
                    <p>11.9. The Terms and Conditions are personal to you. You may not assign your rights or obligations to anyone.</p>
                </div>
                <div className="content-section" id="contact-information">
                    <h2>Contact Information</h2>
                    <p><strong>CommerceGate Payment Solutions S.L.</strong></p>
                    <p>Registered at: World Trade Center, North building, 4th floor. C/Moll de Barcelona S/N, 08039, Barcelona, Spain.</p>
                    <p><strong>CommerceGate</strong> is a private limited liability company, incorporated under the laws of Spain and registered under the number B67016634. CommerceGate is a EU authorized Payment Institution, licensed by the Bank of Spain under the license number 6896.</p>
                    <p>Phone: +34 931 149 991 · <a href="mailto:info@cgpaytech.com">info@cgpaytech.com</a> · <a href="https://www.cgpaytech.com">www.cgpaytech.com</a></p>
                    <p>For questions, enquiries or comments concerning our PRIVACY AND DATA PROTECTION POLICY, please contact our Data Protection Officer at <a href="mailto:dpo@cgpaytech.com">dpo@cgpaytech.com</a></p>
                </div>

                <div className="content-section">
                    <p>Last updated:  19/11/2021</p>
                </div>
            </div>
        </div>
        </div>
        </Main>
    );
};

export default TermsAndConditionsPage;