import React from 'react';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { DOMAINS } from '../config';

interface I18nProps {
  host: string;
}

/**
 * I18nProvider component.
 * Loads and initializes i18n translations based on the host domain.
 */
const I18nProvider: React.FC<I18nProps> = ({ host }) => {
  // Extract domain from host
  const domain = window.location.host.split(':')[0];
  const cgpaytech = (require as any).context('./cgpaytech/locales', true, /\.json$/);
  const cgbilling = (require as any).context('./cgbilling/locales', true, /\.json$/);

  const loadTranslations = () => {
    // Checks if the passed domain is included in the cgbilling domains
    const isCgbilling = DOMAINS.cgbilling.includes(domain);

    // Load translations based on the domain
    return isCgbilling ? cgbilling : cgpaytech;
  };

  const resources = {
    en: { translation: loadTranslations()('./en.json') },
    es: { translation: loadTranslations()('./es.json') },
    fr: { translation: loadTranslations()('./fr.json') },
    de: { translation: loadTranslations()('./de.json') },
    it: { translation: loadTranslations()('./it.json') },
    nl: { translation: loadTranslations()('./nl.json') },
    pt: { translation: loadTranslations()('./pt.json') }
  };

  i18n.use(initReactI18next).init({
    resources,
    lng: 'en',
    interpolation: {
      escapeValue: true,
    },
  });

  return null;
};

export default I18nProvider;
