import { Layout } from "antd";
import { Outlet } from "react-router-dom";
import { LanguageProvider } from '../components/LanguageContext';
import HeaderNav from "../components/HeaderNav";
import Footer from "../components/Footer";

const { Content, Header } = Layout;

const MainLayout = () => {
  return (
    <Layout>
      <LanguageProvider>
      <Header>
        <HeaderNav />
      </Header>
      <Content>
        <Outlet />
      </Content>
      <Footer />
      </LanguageProvider>
    </Layout>
  )
}

export default MainLayout;