import LocaleSwitcherList from './LocaleSwitcherList';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Footer = () => {
  const { t } = useTranslation();
  const currentYear = new Date().getFullYear();
    return (
      <div className="layout-footer">
        <div className="layout-container">
            <div className="footer-badges">
                <div className="badges badges-left">
                    <span className="badge badge-visa"></span>
                    <span className="badge badge-mastercard"></span>
                    <span className="badge badge-switchsolo"></span>
                    <span className="badge badge-jcb"></span>
                    <span className="badge badge-maestro"></span>
                    <span className="badge badge-visadebit"></span>
                    <span className="badge badge-visaelectron"></span>
                    <span className="badge badge-mastercarddebit"></span>
                    <span className="badge badge-delta"></span>
                    <span className="badge badge-dinersclub"></span>
                </div>
                <div className="badges badges-right">
                    <span className="badge badge-verisignsecured"></span>
                    <span className="badge badge-visaverified"></span>
                    <span className="badge badge-mastercardsecure"></span>
                    <span className="badge badge-pcisecure"></span>
                    <span className="badge badge-encryption"></span>
                </div>
            </div>
            <div className="footer-links">
                <div className="footer-links-left">
                        <div className="footer-copyright">{currentYear} {t('footer.copyright')}</div>
                    <div className="footer-nav">
                        <ul className="nav nav-horizontal">
                            <li><Link to="/secure/termsAndConditions">{t('footer.nav.termsAndConditions')}</Link></li>
                            <li><Link to="/secure/privacyPolicy">{t('footer.nav.privacyPolicy')}</Link>
                            </li>
                            <li><Link to="/secure/faq">{t('footer.nav.faq')}</Link></li>
                        </ul>
                    </div>
                </div>
                <div className="footer-links-right">
                    <div className="footer-lang">
                        <LocaleSwitcherList />
                    </div>
                    {/* <div className="footer-socials">
                        <div className="socials">
                            <a className="social" href="https://www.facebook.com/CommerceGate/" target="_blank" rel="noopener noreferrer"><i className="icon icon-facebook"></i></a>
                            <a className="social" href="https://twitter.com/CommerceGate" target="_blank" rel="noopener noreferrer"><i className="icon icon-twitter"></i></a>
                            <a className="social" href="https://www.linkedin.com/company/commercegate" target="_blank" rel="noopener noreferrer"><i className="icon icon-linkedin"></i></a>
                        </div>
                    </div> */}
                </div>
            </div>
        </div>
    </div>
    );
  };
  
export default Footer;
  