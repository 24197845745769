import Main from "../components/Main";
import CollapsiblePanel from "../components/CollapsiblePanel";
import BlockInfo from "../components/BlockInfo";
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const FaqPage = () => {
  const { t } = useTranslation();

  const handleBackToTopClick = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <Main>
        <div className="row faq-page">
            <div className="col-sm-8 col-sm-offset-2 col-md-6 col-md-offset-3">
            <BlockInfo
                title={t('faq.title')}
                subtitle=""
                description={t('faq.description')}
            />
            <div className="panel-group" id="faq" role="tablist" aria-multiselectable="true">
                <CollapsiblePanel
                    title={t('faq.info.1panel.title')}
                    content={
                        <>
                        <p>{t('faq.info.1panel.content.1line.description')}</p>
                        <p>{t('faq.info.1panel.content.2line.description')}
                            <Link to="/secure/findOrders">{t('faq.info.1panel.content.2line.descriptionlink')}</Link>
                        </p>
                        <p>{t('faq.info.1panel.content.3line.description')}
                            <Link to="/secure/customerSupport">{t('faq.info.1panel.content.3line.descriptionlink')}</Link>
                        </p>
                        <p><Link to="#" onClick={handleBackToTopClick}><strong>{t('faq.info.backToTop.description')}</strong></Link></p>
                        </>
                    }
                />
                <CollapsiblePanel
                    title={t('faq.info.2panel.title')}
                    content={
                        <>
                        <p>{t('faq.info.2panel.content.1line.description')}</p>
                        <p>{t('faq.info.2panel.content.2line.description')}</p>
                        <p><Link to="#" onClick={handleBackToTopClick}><strong>{t('faq.info.backToTop.description')}</strong></Link></p>
                        </>
                    }
                />
                <CollapsiblePanel
                    title={t('faq.info.3panel.title')}
                    content={
                        <>
                        <p><strong>{t('faq.info.3panel.content.1line.description')}</strong></p>
                        <p>{t('faq.info.3panel.content.2line.description')} <Link to="/secure/customerSupport">{t('faq.info.3panel.content.2line.descriptionlink')}</Link></p>
                        <p><Link to="#" onClick={handleBackToTopClick}><strong>{t('faq.info.backToTop.description')}</strong></Link></p>
                        </>
                    }
                />
                <CollapsiblePanel
                    title={t('faq.info.4panel.title')}
                    content={
                        <>
                        <p>{t('faq.info.4panel.content.1line.description')}</p>
                        <p>{t('faq.info.4panel.content.2line.description')} <Link to="/secure/customerSupport">{t('faq.info.4panel.content.2line.descriptionlink')}</Link></p>
                        <p><Link to="#" onClick={handleBackToTopClick}><strong>{t('faq.info.backToTop.description')}</strong></Link></p>
                        </>
                    }
                />
                <CollapsiblePanel
                    title={t('faq.info.5panel.title')}
                    content={
                        <>
                        <p>{t('faq.info.5panel.content.1line.description')}</p>
                        <p><Link to="#" onClick={handleBackToTopClick}><strong>{t('faq.info.backToTop.description')}</strong></Link></p>
                        </>
                    }
                />
                <CollapsiblePanel
                    title={t('faq.info.6panel.title')}
                    content={
                        <>
                        <p>{t('faq.info.6panel.content.1line.description')}</p>
                        <p><Link to="#" onClick={handleBackToTopClick}><strong>{t('faq.info.backToTop.description')}</strong></Link></p>
                        </>
                    }
                />
                <CollapsiblePanel
                    title={t('faq.info.7panel.title')}
                    content={
                        <>
                        <p>{t('faq.info.7panel.content.1line.description')}</p>
                        <p><Link to="#" onClick={handleBackToTopClick}><strong>{t('faq.info.backToTop.description')}</strong></Link></p>
                        </>
                    }
                />
                <CollapsiblePanel
                    title={t('faq.info.8panel.title')}
                    content={
                        <>
                        <p>{t('faq.info.8panel.content.1line.description')}</p>
                        <p>{t('faq.info.8panel.content.2line.description')}</p>
                        <p><Link to="#" onClick={handleBackToTopClick}><strong>{t('faq.info.backToTop.description')}</strong></Link></p>
                        </>
                    }
                />
                <CollapsiblePanel
                    title={t('faq.info.9panel.title')}
                    content={
                        <>
                        <p>{t('faq.info.9panel.content.1line.description')}</p>
                        <p><Link to="/secure/customerSupport">{t('faq.info.9panel.content.1line.descriptionlink')}</Link></p>
                        <p><Link to="#" onClick={handleBackToTopClick}><strong>{t('faq.info.backToTop.description')}</strong></Link></p>
                        </>
                    }
                />
                <CollapsiblePanel
                    title={t('faq.info.10panel.title')}
                    content={
                        <>
                        <p>{t('faq.info.10panel.content.1line.description')}</p>
                        <p>{t('faq.info.10panel.content.2line.description')}<Link to="/secure/termsAndConditions">{t('faq.info.10panel.content.2line.descriptionlink')}</Link></p>
                        <p><Link to="#" onClick={handleBackToTopClick}><strong>{t('faq.info.backToTop.description')}</strong></Link></p>
                        </>
                    }
                />
                <CollapsiblePanel
                    title={t('faq.info.11panel.title')}
                    content={
                        <>
                        <p>{t('faq.info.11panel.content.1line.description')}</p>
                        <p>{t('faq.info.11panel.content.2line.description')}<Link to="/secure/customerSupport">{t('faq.info.11panel.content.2line.descriptionlink')}</Link></p>
                        <p>{t('faq.info.11panel.content.3line.description')}</p>
                        <p><Link to="#" onClick={handleBackToTopClick}><strong>{t('faq.info.backToTop.description')}</strong></Link></p>
                        </>
                    }
                />
                <CollapsiblePanel
                    title={t('faq.info.12panel.title')}
                    content={
                        <>
                        <p>{t('faq.info.12panel.content.1line.description')}</p>
                        <p>{t('faq.info.12panel.content.2line.description')}</p>
                        <p><Link to="#" onClick={handleBackToTopClick}><strong>{t('faq.info.backToTop.description')}</strong></Link></p>
                        </>
                    }
                />
                <CollapsiblePanel
                    title={t('faq.info.13panel.title')}
                    content={
                        <>
                        <p>{t('faq.info.13panel.content.1line.description')}<Link to="/secure/customerSupport/">{t('faq.info.13panel.content.1line.descriptionlink')}</Link>
                        {t('faq.info.13panel.content.1line.additionalDescription')}
                        </p>
                        <p><Link to="#" onClick={handleBackToTopClick}><strong>{t('faq.info.backToTop.description')}</strong></Link></p>
                        </>
                    }
                />
                </div>
            </div>
        </div>
    </Main>
  );
};

export default FaqPage;