import React, { useState } from "react";

interface CollapsiblePanelProps {
  title: string;
  content: React.ReactNode;
}

function CollapsiblePanel({ title, content }: CollapsiblePanelProps) {
  const [isOpen, setIsOpen] = useState(false);

  const togglePanel = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="panel panel-default">
      <div className="panel-heading" role="tab" onClick={togglePanel}>
        <div className="panel-title">
        <a className={!isOpen ? "collapsed" : ""}>{title}</a>
        </div>
      </div>
      <div className={`panel-collapse collapse ${isOpen ? "in" : ""}`}>
        {content}
      </div>
    </div>
  );
}

export default CollapsiblePanel;
