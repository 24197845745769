// Interface defining the expected structure of the API response
export interface ApiResponse {
  success: boolean;
  data?: any;
  results?: any,
  errMessage?: string | null;
  offset?: number;
  total?: number;
}

// Base URL for the backend API
const BASE_API_URL = "/api";

/**
 * Handles the response from the fetch request.
 * @param response The response object from the fetch API call.
 * @returns A promise that resolves to an ApiResponse object.
 */
const handleResponse = async (response: Response): Promise<ApiResponse> => {
  if (!response.ok) {
    let errMessage = 'Service is not available temporary.';
    try {
      const errorData = await response.json();
      // Customize the error message based on the status code
      if (response.status === 424) {
        errMessage = errorData.errMessage || 'Service is not available temporary.';
      } 
    } catch (error) {
      console.error('Error parsing error response:', error);
    }
    // Return an ApiResponse object indicating failure
    return {
      success: false,
      errMessage: errMessage,
    };
  }

  // If the response is successful, parse the JSON body
  const data = await response.json();
  // Return the ApiResponse object with success status and any additional data
  return {
    success: true,
    errMessage: data.errMessage,
    ...data.result,
  };
};

/**
 * Fetches data from the specified endpoint.
 * @param endpoint The endpoint to fetch data from.
 * @param method The HTTP method to use for the request (default is 'GET').
 * @param body An optional body to include in the request for POST methods.
 * @returns A promise that resolves to an ApiResponse object.
 */
export const fetchData = async (
  endpoint: string,
  method: string = 'GET',
  body?: object
): Promise<ApiResponse> => {
  // Configure the request options
  const requestOptions: RequestInit = {
    method,
    headers: {
      'Content-Type': 'application/json'
    },
    body: body ? JSON.stringify(body) : undefined
  };

  try {
    const response = await fetch(`${BASE_API_URL}/${endpoint}`, requestOptions);
    return await handleResponse(response);
  } catch (error) {
    return {
      success: false,
      errMessage: error instanceof Error ? error.message : 'Unknown error',
    };
  }
};