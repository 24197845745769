import React, { useState, useEffect } from 'react';
import Main from "../components/Main";
import BlockInfo from "../components/BlockInfo";
import CancelSubscriptionsForm from "../components/CancelSubscriptionsForm";
import { useTranslation } from 'react-i18next';

const CancelSubscriptions = () => {
  const { t } = useTranslation();

  const [pageTitle, setPageTitle] = useState(t('cards.data.cancelSubscriptions.pageTitle'));
  const [pageDescription, setPageDescription] = useState(t('cards.data.cancelSubscriptions.pageDescription'));

  useEffect(() => {
    setPageTitle(t('cards.data.cancelSubscriptions.pageTitle'));
    setPageDescription(t('cards.data.cancelSubscriptions.pageDescription'));
  }, [t]);

  const handleFormSubmission = () => {
    setPageTitle(t('cards.data.cancelSubscriptions.submittedPageTitle'));
    setPageDescription(t('cards.data.cancelSubscriptions.submittedPageDescription'));
  };

  return (
    <Main>
      <BlockInfo
        title={pageTitle}
        description={pageDescription}
      />
      <CancelSubscriptionsForm onFormSubmit={handleFormSubmission}/>
    </Main>
  )
};

export default CancelSubscriptions;