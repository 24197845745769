import React from 'react';

const SwaggerUIFrame: React.FC = () => {
  const swaggerUIUrl = process.env.NODE_ENV === 'development' 
    ? 'http://localhost:3029/api/doc'
    : '/api/doc';

  return (
    <div style={{ height: '100vh', width: '100%' }}>
      <iframe src={swaggerUIUrl} title="Swagger UI" style={{ border: 'none', height: '100%', width: '100%' }} />
    </div>
  );
};

export default SwaggerUIFrame;