import { Link } from 'react-router-dom';

const Card = ({iconUrl, title, description, href, index}: any) => {
  return (
    <li key={index} className="card card-link">
          <Link to={href} className="card-href">
              <div className="card-icon">
                  <img height="68" alt="card-icon" src={iconUrl}></img>
              </div>
              <div className="card-content">
                  <h3 className="card-title">{title}</h3>
                  <p>{description}</p>
              </div>
          </Link>
      </li>
  );
}

export default Card;