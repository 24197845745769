import React, { useState, useEffect, useRef } from 'react';
import Captcha from './Captcha';
import FindOrdersResultTable from "./FindOrdersResultTable";
import { useTranslation } from 'react-i18next';
import { validateLastFourDigits, validateEmail, validateUsername } from '../helpers/inputValidations';

interface FindOrdersFormProps {
  onFormSubmit: () => void;
}

const FindOrdersForm: React.FC<FindOrdersFormProps> = ({ onFormSubmit }) => {
  const { t } = useTranslation();

  // Define icons path
  const emailIconPath = '../images/formicon-email.svg';
  const userNameIconPath = '../images/formicon-username.svg';

  const [cardNumber, setCardNumber] = useState('');
  const [userData, setUserData] = useState('');
  const [showOptions, setShowOptions] = useState(false);
  const [captchaValue, setCaptchaValue] = useState<string | null>(null);
  const [error, setError] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);

  const [selectedDropdownOption, setSelectedDropdownOption] = useState(t('cards.data.findOrders.defaultOptionSelect'));
  const [buttonText, setButtonText] = useState(t('cards.data.findOrders.defaultButtonText'));

  const dropdownRef = useRef<HTMLDivElement>(null);

  const [inputPlaceholder, setInputPlaceholder] = useState(t('cards.data.findOrders.emailOrUsernamePlaceholder'));

  const [touched, setTouched] = useState({lastFourDigits: false, emailOrUsername: false});

  const [lastFourDigitsError, setLastFourDigitsError] = useState('');
  const [tableDataLast4, setTableDataLast4] = useState<{ column: string; value: string; } | null>(null);
  
  const [emailOrUsernameError, setEmailOrUsernameError] = useState('');
  const [tableDataEmailOrUsername, setTableDataEmailOrUsername] = useState<{ column: string; value: string; } | null>(null);

  /*
  Use effects
  */

  useEffect(() => {
    // Reset form fields and related states
    setCardNumber('');
    setUserData('');
    setLastFourDigitsError('');
    setEmailOrUsernameError('');
    setTouched({lastFourDigits: false, emailOrUsername: false});
    // Resetting selectedDropdownOption and inputPlaceholder to default values
    setSelectedDropdownOption(t('cards.data.findOrders.defaultOptionSelect'));
    setInputPlaceholder(t('cards.data.findOrders.emailOrUsernamePlaceholder'));
  }, [t]);

  useEffect(() => {
    const validCardNumber = touched.lastFourDigits && cardNumber && validateLastFourDigits(cardNumber);
    const validEmailOrUsername = touched.emailOrUsername && userData && 
      ((selectedDropdownOption === t('cards.data.findOrders.emailOrUsernamePlaceholder') && validateEmail(userData)) ||
      (selectedDropdownOption === t('cards.data.findOrders.usernameOptionSelect') && validateUsername(userData)));
    const validCaptcha = !!captchaValue;

    // Update button text based on the validation state of the fields
    if (!validCardNumber || !validEmailOrUsername || !validCaptcha) {
      // Set the text to show how many fields are invalid
      const invalidFields = [!validCardNumber, !validEmailOrUsername, !validCaptcha].filter(isInvalid => isInvalid).length;
      setButtonText(`${invalidFields} ${t('cards.data.findOrders.unfilledButtonText')}`);
    } else {
      setButtonText(t('cards.data.findOrders.submittedButtonText'));
    }
}, [cardNumber, userData, captchaValue, touched, t, selectedDropdownOption]);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setShowOptions(false);
      }
    }
  
    if (showOptions) {
      document.addEventListener("mousedown", handleClickOutside);
    }
  
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showOptions]);

  useEffect(() => {
    if (touched.emailOrUsername) {
        if (selectedDropdownOption === t('cards.data.findOrders.emailOrUsernamePlaceholder')) {
            if (!validateEmail(userData)) setEmailOrUsernameError('Please enter a valid email.');
            else setEmailOrUsernameError('');
        } else if (selectedDropdownOption === t('cards.data.findOrders.usernameOptionSelect')) {
            if (!validateUsername(userData)) setEmailOrUsernameError('Please enter a valid username.');
            else setEmailOrUsernameError('');
        }
    }
  }, [selectedDropdownOption, userData, t, touched.emailOrUsername]);

  /*
  Handlers
  */

  const handleCaptchaChange = (value: string | null) => {
    setCaptchaValue(value);
  };

  const toggleOptionsVisibility = () => {
    setShowOptions(!showOptions);
  };

  const handleOptionSelect = (value: string) => {
    setSelectedDropdownOption(value);
    setInputPlaceholder(value);
    setUserData('');
    setShowOptions(false);
    setEmailOrUsernameError('');
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    setTouched(prev => ({ ...prev, [name]: true }));
  
    if (name === 'lastFourDigits') {
      if (value.length > 6) {
        const trimmedValue = value.slice(-6);
        setCardNumber(trimmedValue);
      } else {
        setCardNumber(value);
      }
      // Validate immediately after update
      if (!validateLastFourDigits(value)) {
        setLastFourDigitsError('Please enter exactly 4 digits.');
      } else {
        setLastFourDigitsError('');
      }
    } else if (name === 'emailOrUsername') {
      setUserData(value);
      // Perform validation based on the selected option
      if (selectedDropdownOption === t('cards.data.findOrders.emailOrUsernamePlaceholder') && !validateEmail(value)) {
        setEmailOrUsernameError('Please enter a valid email.');
      } else if (selectedDropdownOption === t('cards.data.findOrders.usernameOptionSelect') && !validateUsername(value)) {
        setEmailOrUsernameError('Please enter a valid username.');
      } else {
        setEmailOrUsernameError('');
      }
    }
  };

  const handleInputBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    setTouched((prev) => ({ ...prev, [name]: true }));
  
    if (name === 'lastFourDigits') {
      // Validate lastFourDigits
      if (!validateLastFourDigits(value)) {
        setLastFourDigitsError('Please enter exactly 4 digits.');
      } else {
        setLastFourDigitsError('');
      }
    } else if (name === 'emailOrUsername') {
      // Perform validation based on the selected option
      if (selectedDropdownOption === t('cards.data.findOrders.emailOrUsernamePlaceholder') && !validateEmail(value)) {
        setEmailOrUsernameError('Please enter a valid email.');
      } else if (selectedDropdownOption === t('cards.data.findOrders.usernameOptionSelect') && !validateUsername(value)) {
        setEmailOrUsernameError('Please enter a valid username.');
      } else {
        setEmailOrUsernameError('');
      }
    }
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
  
    if ((touched.lastFourDigits && lastFourDigitsError) || (touched.emailOrUsername && emailOrUsernameError) || !cardNumber || !userData || !captchaValue) {
      setError(true);
      return;
    }
  
    setError(false);
    setFormSubmitted(true);
    // Determine which value to use for emailOrUsername based on selectedDropdownOption
    const emailOrUsernameValue = selectedDropdownOption === t('cards.data.findOrders.emailOrUsernamePlaceholder') ? 'email' : 'user_name';
    setTableDataEmailOrUsername({ column: emailOrUsernameValue, value: userData });
    setTableDataLast4({ column: "code_last4", value: cardNumber })
    onFormSubmit();
  };

  const canSubmit = cardNumber && userData && captchaValue && !lastFourDigitsError && !emailOrUsernameError;

  return formSubmitted ? (
    <FindOrdersResultTable
      lastFourDigits={
        formSubmitted && tableDataLast4 ? 
          { column: 'code_last4', value: tableDataLast4.value }
          : null
      }
      emailOrUsername={
        formSubmitted && tableDataEmailOrUsername ? 
          { column: selectedDropdownOption === t('cards.data.findOrders.emailOrUsernamePlaceholder') ? 'email' : 'user_name', value: tableDataEmailOrUsername.value }
          : null
      }
      formSubmitted={formSubmitted} />
  ) : (
    <div className="card card-form search-card-form">
      <form
      method="post"
      action="findOrders.html"
      id="findOrdersForm"
      name="findOrdersForm"
      data-toggle="some-filled"
      data-filled-min="3"
      className="form-justify"
      onSubmit={handleSubmit}
      >
        {error && (
          <div className="error-message">
            {t('cards.data.findOrders.errors.noOrders')}
          </div>
        )}
        <div className="form-group">
            <label htmlFor="lastFourDigits" className="form-icon">
              <img src="../images/formicon-card.svg"
              height="28" alt="" />
            </label>
            <input
              name="lastFourDigits"
              id="lastFourDigits"
              className={`form-control ${lastFourDigitsError ? 'input-error' : ''}`}
              type="tel"
              value={cardNumber}
              onBlur={handleInputBlur}
              onChange={handleInputChange}
              placeholder={t('cards.data.findOrders.lastFourDigitsPlaceholder')}
              pattern="[0-9]*"
              inputMode="numeric"
            />
            <label id="card-label" htmlFor="lastFourDigits" className="form-group-label">
            {t('cards.data.findOrders.lastFourDigitsPlaceholder')}
            </label>
            {touched.lastFourDigits && lastFourDigitsError && <div className="error-message">{lastFourDigitsError}</div>}
          </div>

          {/* drop-down */}
          <div className="form-group">
            <label htmlFor="emailOrUsername" className="form-icon">
              <img
                src={selectedDropdownOption === t('cards.data.findOrders.emailOrUsernamePlaceholder') ? emailIconPath : userNameIconPath}
                height="28" alt="" />
            </label>
            <input
              name="emailOrUsername"
              id="emailOrUsername"
              className={`form-control ${emailOrUsernameError ? 'input-error' : ''}`}
              type="text"
              value={userData}
              onBlur={handleInputBlur}
              onChange={handleInputChange}
              placeholder={inputPlaceholder}
            />
            {touched.emailOrUsername && emailOrUsernameError && <div className="error-message">{emailOrUsernameError}</div>}
          <div 
            className={`form-group-label dropdown-arrow-find-forms ${showOptions ? 'open' : ''}`}
            onClick={toggleOptionsVisibility}
          >
            {selectedDropdownOption}
          </div>
            {showOptions && (
              <div className="selectize-dropdown" ref={dropdownRef}>
                <div className="selectize-dropdown-content">
                  <div
                    className="option"
                    data-value="email"
                    onClick={() => handleOptionSelect(t('cards.data.findOrders.emailOrUsernamePlaceholder'))}
                    >
                    {t('cards.data.findOrders.emailOrUsernamePlaceholder')}
                  </div>
                  <div
                    className="option"
                    data-value="userName"
                    onClick={() => handleOptionSelect(t('cards.data.findOrders.usernameOptionSelect'))}
                    >
                    {t('cards.data.findOrders.usernameOptionSelect')}
                  </div>
                </div>
              </div>
            )}
          </div>

          <div className="form-group recaptcha">
            <div
              className="g-recaptcha"
            >
              <Captcha onChange={handleCaptchaChange} />
            </div>
          </div>

          {/* Form buttons */}
          <div className="form-buttons">
            <button
              type="submit"
              className="btn btn-primary"
              disabled={!canSubmit}
              form="findOrdersForm"
            >
              {buttonText}
            </button>
          </div>
        </form>
      </div>
  );
};

export default FindOrdersForm;