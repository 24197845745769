import React, { Suspense } from 'react';
import { Link } from 'react-router-dom';
import Main from "../components/Main";
import BlockInfo from "../components/BlockInfo";
import { useTranslation } from 'react-i18next';

// Lazy load of the CustomerSupport Component
const CustomerSupportComponent = React.lazy(() => import('../components/CustomerSupportComponent'));


const CustomerSupport = () => {
  const { t } = useTranslation();

  const descriptionWithLink = (
    <span>
      {t('cards.data.customerSupport.pageDescription')} <Link to="/secure/faq">{t('cards.data.customerSupport.descriptionlink')}</Link>
    </span>
  );

  return (
    <Main>
      <div className="row">
        <div className="col-md-8 col-md-offset-2">
          <BlockInfo
            title={t('cards.data.customerSupport.title')}
            description={descriptionWithLink}/>
          <Suspense fallback={<div>Loading Customer Support Form...</div>}>
            <CustomerSupportComponent/>
          </Suspense>
        </div>
      </div>
    </Main>
  );
};

export default CustomerSupport;