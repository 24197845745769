import { useCallback } from 'react';

const useSmoothScroll = (): (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void => {
  const handleClick = useCallback((event: React.MouseEvent<HTMLAnchorElement, MouseEvent>): void => {
    event.preventDefault();
    const hash = event.currentTarget.getAttribute('href');

    if (hash) {
      const targetElement = document.querySelector(hash) as HTMLElement | null;

      if (targetElement) {
        window.scrollTo({
          top: targetElement.offsetTop,
          behavior: 'smooth',
        });

        window.history.pushState(null, '', hash || '');

        event.currentTarget.blur();
      }
    }
  }, []);

  return handleClick;
};

export default useSmoothScroll;
