import React from "react";
import { Link } from 'react-router-dom';

interface InfoProps {
  title: string;
  subtitle: string;
  description: string;
  href: string,
  btnName: string;
}

const BlockAdditionalInfo: React.FC<InfoProps> = ({ title, subtitle, description, href, btnName }) => {
  return (
    <div className="block-info">
      <h2>{title}</h2>
      <h2>{subtitle}</h2>
      <p>{description}</p>
      <Link to={href}>{btnName}</Link>
    </div>
  );
};

export default BlockAdditionalInfo;
