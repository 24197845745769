/* Validation rules */

// "lastFourDigits" input validation
export const validateLastFourDigits = (input: string): boolean => {
    const regex = /^\d{4}$/; // Regular expression for exactly four digits
    return regex.test(input);
};

// "lastSixDigits" input validation
export const validateLastSixDigits = (input: string): boolean => {
    const regex = /^\d{6}$/;
    return regex.test(input);
};

// Email validation
export const validateEmail = (email: string): boolean => {
    const trimmedEmail = email.trim();
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    return regex.test(trimmedEmail);
};

// Username validation
export const validateUsername = (username: string): boolean => {
    // This allows a wider range of characters, including special characters, spaces, dots, and hyphens
    const regex = /^[\w\s.-@]+$/;
  return regex.test(username) && username.length >= 3;
  };