import React, { useEffect } from 'react';

const ScrollClassComponent: React.FC = () => {
  useEffect(() => {
    const handleScroll = (): void => {
      const shouldAddClass = window.scrollY > 0;
      document.body.classList.toggle('scrolled', shouldAddClass);
    };

    window.addEventListener('scroll', handleScroll);

    handleScroll();

    return (): void => window.removeEventListener('scroll', handleScroll);
  }, []);

  return null;
};

export default ScrollClassComponent;
