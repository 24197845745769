import HeaderBrand from './HeaderBrand';
import HeaderBurger from './HeaderBurger';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import LocaleSwitcherSelect from "../components/LocaleSwitcherSelect";

const HeaderNav = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const handleLinkClick = (path: string) => (event: { preventDefault: () => void; }) => {
    if (location.pathname === path) {
      event.preventDefault();
      navigate(0);
    }
  };

  return (
    <div className="layout-header">
      <input type="checkbox" id="header-burger-menu-state" className="header-burger-menu-state" />
      <div className="layout-container">
        <HeaderBrand />
        <div className="header-burger-menu">
          <div className="header-lang dropdown">
            <LocaleSwitcherSelect />
          </div>
          <div className="header-nav">
            <ul className="nav nav-horizontal">
              <li>
                <Link to="/secure/findOrders" onClick={handleLinkClick("/secure/findOrders")}>{t('findOrders')}</Link>
              </li>
              <li>
                <Link to="/secure/cancelSubscriptions" onClick={handleLinkClick("/secure/cancelSubscriptions")}>{t('cancelMembership')}</Link>
              </li>
              <li>
                <Link to="/secure/customerSupport" onClick={handleLinkClick("/secure/customerSupport")}>{t('customerSupport')}</Link>
              </li>
              <li>
                <Link className="merchants-btn btn btn-primary" to="https://merchants.cgpaytech.com">{t('myCommerceGate')}</Link>
              </li>
            </ul>
          </div>
        </div>
        <HeaderBurger />
      </div>
    </div>
  );
  
};

export default HeaderNav;