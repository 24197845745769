import Card from "./Card";
import { useTranslation } from 'react-i18next';

const Cards = () => {
  const { t } = useTranslation();

  const data = [
      {
        href:'/secure/findOrders',
        iconUrl:`${process.env.PUBLIC_URL}/images/frontpage-findcard.svg`,
        title: t('cards.data.findOrders.title'),
        description: t('cards.data.findOrders.description')
      },
      {
        href:'/secure/cancelSubscriptions',
        iconUrl:`${process.env.PUBLIC_URL}/images/frontpage-membership.svg`,
        title: t('cards.data.cancelSubscriptions.title'),
        description: t('cards.data.cancelSubscriptions.description')
      },
      {
        href:'/secure/customerSupport',
        iconUrl:`${process.env.PUBLIC_URL}/images/frontpage-contactsupport.svg`,
        title: t('cards.data.customerSupport.title'),
        description: t('cards.data.customerSupport.description')
      },
  ];

  return (
    <ul className="cards">
        {data.map((props, index) => (
            <Card key={index} {...props} />
        ))}
    </ul>
  );
}

export default Cards;



