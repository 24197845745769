import React from "react";
import BlockInfo from "./BlockInfo";
import BlockContacts from "./BlockContacts";
import { useTranslation } from 'react-i18next';

interface MainProps {
  children?: React.ReactNode;
  blockInfoProps?: {
    title: string;
    subtitle: string;
    description: string;
  };
  blockAdditionalInfo?: React.ReactNode;
}

const Main: React.FC<MainProps> = ({ children, blockInfoProps, blockAdditionalInfo }) => {
  const { t } = useTranslation();
  const contacts = [
    { country: t('contacts.usa.country'), phone: t('contacts.usa.phone') },
    { country: t('contacts.uk.country'), phone: t('contacts.uk.phone') },
    { country: t('contacts.fr.country'), phone: t('contacts.fr.phone') },
    { country: t('contacts.es.country'), phone: t('contacts.es.phone') },
    { country: t('contacts.ca.country'), phone: t('contacts.ca.phone') },
    { country: t('contacts.aus.country'), phone: t('contacts.aus.phone') }
  ];

  return (
    <div className="layout-content">
      <div className="layout-container">
        {blockInfoProps && (
            <BlockInfo
                title={blockInfoProps.title}
                subtitle={blockInfoProps.subtitle}
                description={blockInfoProps.description}
            />
        )}
        {children && children}
        {blockAdditionalInfo && blockAdditionalInfo}
        <BlockContacts contacts={contacts} />
      </div>
    </div>
  );
};

export default Main;