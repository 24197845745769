import useSmoothScroll from '../hooks/useSmoothScroll';

interface SmoothScrollLinkProps {
    to: string;
    children: React.ReactNode;
  }
  
  const SmoothScrollLink: React.FC<SmoothScrollLinkProps> = ({ to, children }) => {
    const handleClick = useSmoothScroll();
  
    return (
      <a href={to} onClick={handleClick} className="scroll-link">
        {children}
      </a>
    );
};
  
export default SmoothScrollLink;