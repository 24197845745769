import React, { useState, useEffect } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { fetchData, ApiResponse } from '../services/apiService';
import { RECAPTCHA_SITE_KEY } from '../config';

interface CaptchaProps {
  onChange: (value: string | null) => void;
}

const CaptchaComponent: React.FC<CaptchaProps> = ({ onChange }) => {
  const [captchaValue, setCaptchaValue] = useState<string | null>(null);
  const [isVerified, setIsVerified] = useState(false);

  useEffect(() => {
    const verifyCaptcha = async () => {
      if (isVerified) return;

      if (captchaValue) {
        try {
          const data: ApiResponse | null = await fetchData('captcha/verify-captcha', 'POST', {
            captchaValue: captchaValue,
          });

          if (data && data.success) {
            setIsVerified(true);
            onChange(captchaValue);
          } else {
            onChange(null);
          }
        } catch (error) {
          onChange(null);
        }
      }
    };

    verifyCaptcha();
  }, [captchaValue, isVerified, onChange]);

  const handleCaptchaChange = (value: string | null) => {
    if (!isVerified) {
      setCaptchaValue(value);
    }
  };

  return (
    <div>
      <ReCAPTCHA
        sitekey={RECAPTCHA_SITE_KEY}
        onChange={handleCaptchaChange}
      />
    </div>
  );
};

export default CaptchaComponent;
