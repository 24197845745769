import React, { useEffect, useState } from 'react';
import { Table, Spin, Alert } from 'antd';
import { TablePaginationConfig } from 'antd/lib/table';
import { CancelSubscriptionsType, TransactionResultTableProps } from '../types/types';
import { fetchData, ApiResponse } from '../services/apiService';

// Define extended pagination config
interface ExtendedPaginationConfig extends TablePaginationConfig {
  total: number;
}

// Map API data to CancelSubscriptionsType interface
const mapDataToCancelSubscriptionsType = (data: any[]): CancelSubscriptionsType[] => {
  if (!data || data.length === 0) {
    return [];
  }

  return data.map((item) => {
    const createdAt = new Date(item.created_at);
    const date = createdAt.toLocaleDateString();

    return {
      key: item.order_id,
      date: date,
      creditCard: item.code_last4,
      offer: item.transaction_description,
      status: item.status,
      websiteName: item.external_merchant_website,
    };
  });
}

// Generate unique row key for table records
const generateRowKey = (record: CancelSubscriptionsType) => {
  const randomString = Math.random().toString(36).substring(2, 10);
  return randomString
};

/**
 * CancelSubscriptionsResultTable
 * 
 * Component to display subscription history table.
 * Fetches data from API based on search criteria.
 */
  const CancelSubscriptionsResultTable: React.FC<TransactionResultTableProps> = ({
    lastFourDigits,
    emailOrUsername,
    formSubmitted
  }) => {
    const [data, setData] = useState<CancelSubscriptionsType[]>([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [pagination, setPagination] = useState<ExtendedPaginationConfig>({
      current: 1,
      pageSize: 20,
      total: 0,
    });
    const [infoMessage, setInfoMessage] = useState<string | null>(null);
    const [cancelledOrderId, setCancelledOrderId] = useState<string | null>(null);
  
    // Fetch data from API when form submitted or pagination changes
    useEffect(() => {
      const load = async () => {
        setLoading(true);
  
        // Construct criteria array for API request
        const criterias = [];
        const defaultCriteria = { column: 'email', value: 'unknowm@mail' };
        if (emailOrUsername) {
          criterias.push({ column: emailOrUsername.column, value: emailOrUsername.value });
        } else {
          criterias.push(defaultCriteria);
        }
        if (lastFourDigits) {
          criterias.push({ column: lastFourDigits.column, value: lastFourDigits.value });
        } else {
          criterias.push(defaultCriteria);
        }
  
        // Construct request body for API request
        const requestBody = {
          filter: criterias,
          pageNumber: pagination.current,
          pageSize: pagination.pageSize,
        };
  
        // Fetch list of subscriptions
        const responseData: ApiResponse | null = await fetchData('subscriptions', 'POST', requestBody);
        // Handle API response
        if (data && responseData.success) {
          const transformedData = mapDataToCancelSubscriptionsType(responseData.data);
          setData(transformedData);
          if (responseData.total !== pagination.total) {  
            setPagination(prev => ({ ...prev, total: responseData.total ?? prev.total }));
          }
        } else if (responseData && !responseData.success) {
          setError('Service is not available temporary.');
        } else {
          // Handle null response (e.g., network error)
          setError('Service is not available temporary.');
        }
        setLoading(false);
      }
  
      if (formSubmitted && lastFourDigits && emailOrUsername) {
        load();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lastFourDigits, emailOrUsername, formSubmitted, pagination.current, pagination.pageSize]);
  
    // Handle table pagination change
    const handleTableChange = (
      newPagination: TablePaginationConfig
    ) => {
      const current = newPagination.current || 1;
      const pageSize = newPagination.pageSize || 10;
      
      setPagination(prev => ({
        ...prev,
        current,
        pageSize,
      }));
    };

    /*
    * handleCancelClick 
    * Make request to Cancel Subscription
    */
    const handleCancelClick = async (record: CancelSubscriptionsType) => {
      const orderId = record.key || null;
      if (!orderId) {
        setError('Service is not available temporarily.');
        return;
      }
      setLoading(true);
    
      const requestBody = { orderId: orderId };
    
      const responseData: ApiResponse | null = await fetchData('subscription/cancel', 'POST', requestBody);
  
      if (responseData && responseData.success) {
        const updatedData = data.map(item =>
          item.key === record.key ? { ...item, status: 'CANCELLED' } : item
        );
        setData(updatedData);
        setInfoMessage('Subscription successfully cancelled.');
        setCancelledOrderId(orderId);
      } else if (responseData && !responseData.success) {
        setError(responseData.errMessage || 'Service is not available temporary.');
      } else {
        setError('Service is not available temporary.');
      }
      setLoading(false);
    };
  
    // Define table columns
    const columns = [
      {
        title: 'DATE',
        dataIndex: 'date',
        key: 'date',
        className: 'column-date',
      },
      {
        title: 'CREDIT CARD',
        dataIndex: 'creditCard',
        key: 'creditCard',
        className: 'column-credit-card',
      },
      {
        title: 'OFFER',
        dataIndex: 'offer',
        key: 'offer',
        className: 'column-offer',
      },
      {
        title: 'WEBSITE NAME',
        dataIndex: 'websiteName',
        key: 'websiteName',
        className: 'column-website-name',
      },
      {
        title: 'STATUS',
        dataIndex: 'status',
        key: 'status',
        className: 'column-status',
      },
      {
          title: '',
          key: 'action',
          className: 'column-action',
          render: (text: any, record: CancelSubscriptionsType) => (
            <button
              className="cancel-button"
              onClick={() => handleCancelClick(record)}
              disabled={record.status === 'CANCELLED' || record.status === 'CLOSED'}
            >
              Cancel
            </button>
          ),
        },
    ];
  
    return (
      <div className="well" style={{ background: loading ? 'transparent' : '#fff' }}>
        <Spin spinning={loading} size="large" tip="Loading...">
          {error ? (
            <Alert message={error} type="error" showIcon closable onClose={() => setError(null)} />
          ) : (
            <>
               {infoMessage && (
                <Alert message={infoMessage} type="info" showIcon closable onClose={() => setInfoMessage(null)} />
              )}
              {!loading && (
                <>
                  <Table
                    columns={columns}
                    dataSource={data}
                    pagination={{
                      ...pagination,
                      showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} subscriptions`,
                    }}
                    onChange={handleTableChange}
                    rowKey={generateRowKey}
                    rowClassName={(record) => (record.key === cancelledOrderId ? 'highlighted-row' : '')}
                  />
                </>
              )}
            </>
          )}
        </Spin>
      </div>
    );
  };

export default CancelSubscriptionsResultTable;
