import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ConfigProvider, Select } from 'antd';
import enUS from 'antd/lib/locale/en_US';
import esES from 'antd/lib/locale/es_ES';
import frFR from 'antd/lib/locale/fr_FR';
import itIT from 'antd/lib/locale/it_IT';
import deDE from 'antd/lib/locale/de_DE';
import nlNL from 'antd/lib/locale/nl_NL';
import ptPT from 'antd/lib/locale/pt_PT';
import { useLanguage } from './LanguageContext';

const languageLocales: Record<string, any> = {
  en: enUS,
  es: esES,
  fr: frFR,
  de: deDE,
  it: itIT,
  nl: nlNL,
  pt: ptPT,
};

const LocaleSwitcherList = () => {
  const { i18n } = useTranslation();
  const { language, changeLanguage } = useLanguage();

  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language, i18n]);

  const handleLocaleChange = (value: string) => {
    i18n.changeLanguage(value);
    changeLanguage(value);
  };  

  const languageOptions = Object.keys(languageLocales).map((lang) => (
    <Select.Option key={lang} value={lang}>
      {lang.toUpperCase()}
    </Select.Option>
  ));

  return (
    <ul className="nav nav-separated nav-horizontal">
      {Object.keys(languageLocales).map((lang) => (
        <li key={lang}>
          <a href="#" onClick={(e) => { e.preventDefault(); handleLocaleChange(lang); }}>
            {lang.toUpperCase()}
          </a>
        </li>
      ))}
    </ul>
  );
};

export default LocaleSwitcherList;